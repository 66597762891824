import React from "react";
import { navigate } from "gatsby";
import { withAuthentication } from "../../../../hoc/withAuthentication";
import * as styles from "./index.module.css";
import Main from "../../../../components/main";
import Button from "../../../../components/button";
import Input from "../../../../components/input";
import DropdownMenu from "../../../../components/dropdownMenu";
import NotFoundPage from "../../../../components/notFoundPage";
import ContentArea from "../../../../components/contentArea";
import MediaStickyPreview from "../../../../components/mediaStickyPreview";
import TextArea from "../../../../components/textArea";
import ToggleSwitch from "../../../../components/toggleSwitch";
import Consts from "../../../../config/consts";
import Common from "../../../../config/common";
import Util from "../../../../config/util";
import Api from "../../../../config/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown, faArrowUp91, faChevronDown } from "@fortawesome/free-solid-svg-icons";

export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED],
        redirectPage: "/",
    },
    class MediaMintSetup extends React.Component {
        constructor(props) {
            super(props);
            const params = new URLSearchParams(props.location.search);
            this.state = {
                initialising: true,
                incomplete: true,

                mediaId: props.params.media_id,
                media: null,
                collection: null,

                saving: false,
                requiresSigning: props.auth.user.requiresSigning === true,

                selectedAddress: props.auth.user.addresses[0],
                addresses: props.auth.user.addresses,

                applyToEntireCollection: params.get("applyCollection") === "true",

                royaltiesPercent: 0,
                supply: 1,
                isMobile: false
            };
        }

        resize() {
            let currentIsMobile = window.innerWidth <= 768;
            if (currentIsMobile !== this.state.isMobile) {
                this.setState({isMobile: currentIsMobile});
            }
        }

        componentWillUnmount() {
            window.removeEventListener("resize", this.resize.bind(this));
        }

        componentDidMount() {

            window.addEventListener("resize", this.resize.bind(this));
            this.resize();
            Api.market({
                endpoint: "/content/media",
                method: "GET",
                data: {
                    mediaId: this.props.params.media_id,
                },
            })
                .then((res) => {
                    if (Common.objectPropertyExists(res, "data.created", false) !== true) {
                        navigate(`/m/${this.state.mediaId}`);
                    } else {
                        let media_royalties = Common.objectPropertyExists(res, "data.royaltiesPercent", null);
                        let media_supply = Common.objectPropertyExists(res, "data.totalSupply", null);
                        let media_mint_address = Common.objectPropertyExists(res, "data.creator.publicAddress", null);
                        let mint_address = null;

                        // If we have a mint_address lets find it
                        for (let i = 0; i < this.props.auth.user.addresses.length; i++) {
                            if (media_mint_address && this.props.auth.user.addresses[i].publicAddress.toLowerCase() === media_mint_address.toLowerCase()) {
                                mint_address = this.props.auth.user.addresses[i];
                                break;
                            }
                        }

                        this.setState(
                            {
                                initialising: false,
                                selectedAddress: mint_address !== null ? mint_address : this.props.auth.user.addresses[0],
                                media: res.data,
                                collection: res.data.collection || null,
                                royaltiesPercent: media_royalties !== null ? media_royalties : 0,
                                supply: media_supply !== null ? media_supply : 1,
                            },
                            () => {
                                this.validate();
                            }
                        );
                    }
                })
                .catch((e) => {
                    console.error(e);
                    this.setState({
                        initialising: false,
                    });
                });
        }

        selectAddress = (address) => {
            this.setState(
                {
                    selectedAddress: address,
                },
                () => {
                    this.validate();
                }
            );
        };

        onPreSelectRoyalties0 = () => {
            this.onRoyaltyPercentChanged(0);
        };
        onPreSelectRoyalties10 = () => {
            this.onRoyaltyPercentChanged(10);
        };
        onPreSelectRoyalties20 = () => {
            this.onRoyaltyPercentChanged(20);
        };
        onPreSelectRoyalties30 = () => {
            this.onRoyaltyPercentChanged(30);
        };
        onPreSelectRoyalties40 = () => {
            this.onRoyaltyPercentChanged(40);
        };
        onPreSelectRoyalties50 = () => {
            this.onRoyaltyPercentChanged(50);
        };
        onRoyaltyPercentChanged = (text) => {
            if (isNaN(text)) {
                Util.notify.error("Invalid percent entered, please enter a value between 0 and");
            } else {
                this.setState({
                    royaltiesPercent: Number(text),
                });
            }
        };

        onSupplyChanged = (text) => {
            this.setState({
                supply: Number(text),
            });
        };

        onSetupMint = () => {
            if (this.state.saving !== true) {
                this.setState(
                    {
                        saving: true,
                    },
                    () => {
                        Api.exchange({
                            endpoint: "/mint",
                            method: "PATCH",
                            data: {
                                publicAddress: this.state.selectedAddress.publicAddress,
                                mediaId: this.state.mediaId,
                                royalties: this.state.royaltiesPercent,
                                supply: this.state.supply,
                                applyToCollection: this.state.applyToEntireCollection,
                            },
                        })
                            .then((res) => {
                                Util.notify.info("Changes saved successfully.");
                                setTimeout(() => {
                                    navigate(`/m/${this.state.mediaId}`);
                                }, 2000);
                            })
                            .catch((e) => {
                                this.setState(
                                    {
                                        saving: false,
                                    },
                                    () => {
                                        console.error(e);
                                        Util.notify.error("Failed to setup minting for your media. Please try again later.");
                                    }
                                );
                            });
                    }
                );
            }
        };

        getProviderImage = (providerId) => {
            console.log(providerId, this.props.providers);
            if (Array.isArray(this.props.providers) && this.props.providers.length > 0) {
                let provider = this.props.providers.filter((p) => {
                    return p.id === providerId;
                });
                if (provider.length > 0) {
                    return provider[0].image;
                }
            }
            return null;
        };
        // getChainIconUrl = (symbol) => {
        //     for (let i = 0; i < this.state.chains.length; i++) {
        //         if (this.state.chains[i].symbol === symbol) {
        //             return `${process.env.GATSBY_STORAGE}assets/${this.state.chains[i].icon}`;
        //         }
        //     }
        //     return null;
        // };

        onApplyToWholeCollectionCheckChanged = (id, value) => {
            this.setState({
                applyToEntireCollection: value,
            });
        };

        validate = () => {
            this.setState({
                incomplete: this.state.selectedAddress === null,
            });
        };

        refreshPage = () => {
            window.location.reload();
        };

        render() {
            return this.state.media === null ? (
                <Main
                    title={"BMinted"}
                    initialising={this.state.initialising}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    <NotFoundPage notFoundName={"Media"} />
                </Main>
            ) : (
                <Main
                    title={"BMinted"}
                    initialising={this.state.initialising}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    {this.state.media.totalMinted > 0 || (this.state.featuredListing && ["NOT_FOR_SALE", "REMOVED_FROM_SALE"].indexOf(this.state.featuredListing.state) === -1) ? (
                        <ContentArea slim={true} extraTopPadding={true} bottomRule={false}>
                            <ContentArea slim={true} extraTopPadding={true} bottomRule={false}>
                                <TextArea center={true}>
                                    <p className={"caps_and_spaced"}>Minting Setup</p>
                                </TextArea>
                            </ContentArea>
                            <ContentArea header={"Invalid State"} slim={true} bottomRule={true}>
                                <TextArea header={"Minting Options are Immutable."}>
                                    <p>
                                        You can only change media minting values up until the point the media has been minted or listed for sale. After this point you cannot alter these values as we
                                        are working on-chain where data is immutable.
                                    </p>
                                </TextArea>
                                <TextArea>
                                    <Button displayMode={5} hoverMode={6} text={"View Media"} to={`/m/${this.state.mediaId}`} noMargin={true} />
                                </TextArea>
                            </ContentArea>
                        </ContentArea>
                    ) : (
                        <ContentArea slim={true} extraTopPadding={true} bottomRule={false}>
                            <ContentArea header={"Contract Setup"} slim={true} bottomRule={true}>
                                {this.state.addresses.length > 1 && (
                                    <TextArea header={"Choose a Wallet."}>
                                        <p>
                                            Which of your wallets would you like the nft to be minted with? The chosen wallet must have any required funds available and must be your current active
                                            wallet or the process will fail. This is the wallet that your NFT will be associated with once minted or lazy minted.
                                        </p>
                                        <div className={`${styles.address_container}`}>
                                            <div className={styles.address_chain_icon_container}>
                                                <img src={this.getProviderImage(this.state.selectedAddress.provider)} />
                                            </div>
                                            <div className={styles.address_content}>
                                                <h5>{Common.cropTo(this.state.selectedAddress.publicAddress, this.state.isMobile ? 20 : 35)}</h5>
                                                <h6>{this.state.selectedAddress.chainId}</h6>
                                            </div>
                                            <DropdownMenu
                                                iconComponent={
                                                    <span className={styles.dropdown_button}>
                                                        <FontAwesomeIcon icon={faChevronDown} />
                                                    </span>
                                                }
                                                items={[
                                                    ...this.state.addresses.map((address) => {
                                                        return {
                                                            type: "item",
                                                            name: Common.cropTo(address.publicAddress, 26),
                                                            //description: address.chainId,
                                                            image: this.getProviderImage(address.provider),
                                                            loading: false,
                                                            disabled: false,
                                                            onClick: () => {
                                                                this.selectAddress(address);
                                                            },
                                                        };
                                                    }),
                                                ]}
                                            />
                                        </div>
                                        <br />
                                    </TextArea>
                                )}
                                <TextArea header={"Royalties."}>
                                    <p>You can build into your NFT contract that for each future re-sale of your nft you will earn a % of that sale. The maximum percentage you can enter is 50%.</p>
                                    <Input
                                        icon={faCrown}
                                        placeholder={"Royalty Percentage"}
                                        postText={"%"}
                                        onTextChanged={this.onRoyaltyPercentChanged}
                                        defaultValue={this.state.royaltiesPercent}
                                        regex={/^(([0-4]?[0-9])|50)$/g}
                                        maxLength={2}
                                        hideMaxLength={true}
                                    />
                                    <div className={this.state.isMobile ? '' : styles.royaltypreselect_container}>
                                        <Button displayMode={this.state.royaltiesPercent === 0 ? 5 : 7} hoverMode={4} text={"0%"} onClick={this.onPreSelectRoyalties0} style={{ flex: 1, margin: 5 }} />
                                        <Button
                                            displayMode={this.state.royaltiesPercent === 10 ? 5 : 7}
                                            hoverMode={4}
                                            text={"10%"}
                                            onClick={this.onPreSelectRoyalties10}
                                            style={{ flex: 1, margin: 5 }}
                                        />
                                        <Button
                                            displayMode={this.state.royaltiesPercent === 20 ? 5 : 7}
                                            hoverMode={4}
                                            text={"20%"}
                                            onClick={this.onPreSelectRoyalties20}
                                            style={{ flex: 1, margin: 5 }}
                                        />
                                        <Button
                                            displayMode={this.state.royaltiesPercent === 30 ? 5 : 7}
                                            hoverMode={4}
                                            text={"30%"}
                                            onClick={this.onPreSelectRoyalties30}
                                            style={{ flex: 1, margin: 5 }}
                                        />
                                        <Button
                                            displayMode={this.state.royaltiesPercent === 40 ? 5 : 7}
                                            hoverMode={4}
                                            text={"40%"}
                                            onClick={this.onPreSelectRoyalties40}
                                            style={{ flex: 1, margin: 5 }}
                                        />
                                        <Button
                                            displayMode={this.state.royaltiesPercent === 50 ? 5 : 7}
                                            hoverMode={4}
                                            text={"50%"}
                                            onClick={this.onPreSelectRoyalties50}
                                            style={{ flex: 1, margin: 5 }}
                                        />
                                    </div>
                                    <br />
                                </TextArea>

                                <TextArea header={"Supply."}>
                                    <p>The supply is the quantity of NFT that we will mint. This is hardcoded into the smart contract.</p>
                                    <Input
                                        icon={faArrowUp91}
                                        placeholder={"Supply"}
                                        onTextChanged={this.onSupplyChanged}
                                        defaultValue={this.state.supply}
                                        regex={/^[0-9]+$/g}
                                        maxLength={8}
                                        hideMaxLength={true}
                                    />
                                </TextArea>
                                <br />
                            </ContentArea>

                            {!!this.state.media.collection && (
                                <ContentArea header={"Apply To Collection"} slim={true} bottomRule={true}>
                                    <TextArea header={null}>
                                        <p>
                                            This media is part of your <b>{this.state.media.collection.name}</b> collection, if you'd like we can apply the same minting setup to any other media within
                                            this collection that has not already been setup for minting. Anything added to the collection after this time will need to be setup seperately.
                                            <br />
                                            <ToggleSwitch switch={this.state.applyToEntireCollection} text={"Apply to Whole Collection"} onSwitch={this.onApplyToWholeCollectionCheckChanged} />
                                        </p>
                                        <br />
                                    </TextArea>
                                </ContentArea>
                            )}

                            <ContentArea slim={true} extraTopPadding={true} bottomRule={false}>
                                <TextArea center={true}>
                                    <div className={[styles.flex_row, styles.center]}>
                                        <Button displayMode={8} hoverMode={4} disabled={this.state.saving || this.state.incomplete} text={"Cancel"} to={`/m/${this.state.mediaId}`} />
                                        <Button
                                            displayMode={5}
                                            hoverMode={6}
                                            disabled={this.state.saving || this.state.incomplete}
                                            text={this.state.saving ? "Saving Changes..." : "Save Changes"}
                                            onClick={this.onSetupMint}
                                        />
                                    </div>
                                </TextArea>
                                <br />
                            </ContentArea>
                        </ContentArea>
                    )}

                    <MediaStickyPreview media={this.state.applyToEntireCollection ? this.state.collection : this.state.media} />
                </Main>
            );
        }
    }
);
