// extracted by mini-css-extract-plugin
export var address_chain_icon_container = "index-module--address_chain_icon_container--QUuf5";
export var address_container = "index-module--address_container--Yf2gj";
export var address_content = "index-module--address_content--WlsdC";
export var center = "index-module--center--3njX2";
export var chainbox_container = "index-module--chainbox_container--L4DUw";
export var data_container = "index-module--data_container--1SNhL";
export var data_container_left = "index-module--data_container_left--wmWbP";
export var data_container_right = "index-module--data_container_right--5TsI6";
export var data_outer_container = "index-module--data_outer_container--sHc+J";
export var data_right = "index-module--data_right--bc9kP";
export var delete_button = "index-module--delete_button--5KkQW";
export var dropdown_button = "index-module--dropdown_button--wSVdo";
export var flex_row = "index-module--flex_row--pMxZC";
export var hidden_content = "index-module--hidden_content--qc6Hf";
export var property_container = "index-module--property_container--JXjkI";
export var property_list_item = "index-module--property_list_item--nZQ1b";
export var royaltypreselect_container = "index-module--royaltypreselect_container--ab7qw";